import success from '../../Assets/success.png';
import './Contact.css';
const Contact = ({hide}) => {
    return ( 
        <div className="modal">
            <div className="modal-inner contact-modal-inner">
                <img src={success}></img>
                <p className="confirmed">Confirmed</p>
                <p className="your-request">Your request has been sent</p>
                <button onClick={hide}>ok</button>
            </div>
        </div>
    );
}
 
export default Contact;