export const general = [
    {
        id: 1,
        question:"Can I open an account if I’m not physically in the Nigeria or a Nigeria resident ?",
        answer:"Yes, If you register with a Nigeria number and have access to receive OTP through that number"
    },
    {
        id: 2,
        question:"What is Crediometer?",
        answer:"Crediometer is a digital bank which provides easy-to-use and secured banking and payment solutions for business owners."
    },
    {
        id: 3,
        question:"How do i download the credio app  on my mobile phone ",
        answer:"Simply search for “credio” on your  play store  for android or apple store for IOS users. click on download and that is it "
    },
    {
        id: 4,
        question:"What happens if i forget my password ",
        answer:"Kindly click on the Forgot password option on your Credio app, you would be able to change your password"
    },
    {
        id: 5,
        question:"What do I need to sign up as a user?",
        answer:"After downloading the app on playstore or appstore as the case may be, All you need is your Business name and Phone number to sign up."
    },
    {
        id: 6,
        question:"What companies will you accept as customers?",
        answer:"All Business owners and  companies are potential Credio customers."
    },
    {
        id: 7,
        question:"How does Crediometer make money?",
        answer:"Through the sales of our Credio reader."
    }
]