import './Policy.css'
import Topbar from '../../Components/Topbar/Topbar'
import Footer from '../../Components/Footer/Footer';
import Modal from '../../Components/Modal/Modal';
import { useEffect } from 'react';
const Policy = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return ( 
        <div className="policy">
            <Topbar/>
            <div className="policy body">
                <section className="policy-header faq-header">
                    <p className="page-title page-title-faq page-title-policy">CREDIOMETER LIMITED PRIVACY POLICY</p>
                </section>
                <section className="policy-content">
                    <div className="policy-content-inner">
                        <div>
                            <p className="policy-content-title">1. Introduction</p>
                            <p className="policy-content-text">Crediometer Limited is committed to protecting and preserving the confidentiality of your personally identifiable information when you visit our web app. This policy sets out how we process your personal information through our web app. Your personal information is safe and secure, and processed in accordance with applicable data protection legislation and regulations. Please read the following carefully to understand what happens to personal data that you choose to provide to us, or that we collect from you when you visit this web app. By using our web app, you accept and consent to all the practices as described in this policy.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">2. What information do we collect?</p>
                            <p className="policy-content-text">We collect both personal and Non-Personal Information. Personal Information includes your name, email address contact and phone number. This also includes any information you may supply us with by filling in the forms on our web app. Our web app may collect information about you such as technical information, including a truncated and anonymized version of your Internet protocol (IP) address, browser type and version, operating system and platform, what pages you visit, how long you are on the web app, how you got to the web app (including date and time); page response times, length of visit, what you click on, documents downloaded and download errors. We may also track information using cookies or small text files included in anonymous unique identifier. We use non-personal information to improve our services and customize user experiences. This helps us to track and analyze user pattern on our web app. We may disclose non-personal information to third parties, including our partners, advertisers or marketers. We utilize location data to enhance the functionality and user experience of our services, particularly for the Credio POS feature. The primary reason we use location data is for the Credio POS feature. This feature requires access to the user's location to establish a connection with the Credio POS, ensuring accurate and efficient transactions. Your location data is essential for this functionality, and we assure you that it is used solely for this purpose. It's important to note that we only access your location when you are using our app. We strictly adhere to not using your location data for advertising, analytics, or any other non-essential purposes. Our handling of location data is fully compliant with all relevant laws and regulations, including those set forth by Google Play regarding the use of location data.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">3. Cookies</p>
                            <p className="policy-content-text">We may use cookies to improve our web app's functionality and provide our visitors with a customized online experience. You may change your Internet browser settings to prevent your computer from accepting cookies or to notify you when you receive a cookie so that you may decline its acceptance. Please note, however, if you disable cookies, you may not experience optimal performance of our web app.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">4. How we use your personal information</p>
                            <p className="policy-content-text">We do not trade, sell, rent, disclose or share your personal information for marketing or promotional purposes, with third parties or non-affiliated companies. We can only disclose your personal information to meet a legal obligation or to vendors performing services for us, at our direction and in accordance with our Privacy Policy. We may also share information under an enforceable government request, including an investigation on technical or security concerns or to protect against harm to the rights, property or safety or our users or the public.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">5. How we protect your information</p>
                            <p className="policy-content-text">We use strict procedures and security features to try to prevent unauthorized access and to protect your personal information. We implement security measures such as encryption, firewalls and secure socket layer technology. If you use our card option, the information is encrypted using TrippleDES or 3DES, and stored with a AES with CBC encryption. Please ensure that you keep your passwords safe and log out of your account after each use. While we do our best to protect your personal information, we cannot guarantee the security of any informationtransmitted over the internet. You acknowledge that you understand the associated risks in transmitting information via the internet by using our services.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">6. Your rights regarding the use of your Personal Information.</p>
                            <p className="policy-content-text">You have the right to opt out any marketing or promotional communication from being sent to you. However please note that notwithstanding your preferences, we may continue to send administrative communication to you, including any update on our Privacy Policy.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">7. Links to other Web apps</p>
                            <p className="policy-content-text">In order to serve you better, we may provide link to other web apps. We are not responsible for the security of those web apps, nether are we informed of their privacy policy. Our privacy policy is limited to our web app, and we encourage you to read the Privacy Policy of other web apps before you proceed to use them.</p>
                        </div>
                        <div>
                            <p className="policy-content-title">8. Changes to our privacy policy</p>
                            <p className="policy-content-text">We reserve the right to modify this privacy policy at any time In the event that our privacy policy changes at any time in the future, we shall post the changes to our web app so that you may be notified of the change. Please check back occasionally to be informed of any change if you are concerned.</p>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
}
 
export default Policy;