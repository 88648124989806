export const credioreader = [
    {
        id: 1,
        question:"How do i operate my reader",
        answer:"It is very easy, with just 3 clicks, the Credio merchant app is used to operate the Credio reader"
    },
    {
        id: 2,
        question:"How can i purchase a credio reader",
        answer:"Kindly make your order using the website or call 08055887077 Or chat Support"
    },
    {
        id: 3,
        question:"Can any card be accepted by the reader",
        answer:"Yes! The Credio reader works with all ATM cards"
    }
]