import './App.css';
import Main from './Pages/Main/Main';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Merchant from './Pages/Merchant/Merchant';
import Openaccount from './Pages/Merchant/Openaccount';
import Credio from './Pages/Credio/Credio';
import Contactus from './Pages/Contactus/Contactus';
import Faq from './Pages/FAQ/Faq';
import Policy from './Pages/Policy/Policy';
import { useEffect } from 'react';
import ScrollTop from './Components/ScrollTop';

function App() {
  return (
    <Router>
      <div className="App"> 
        <Routes>
          <Route path='/' element={<Main/>}></Route>
          <Route path='/merchant' element={<Merchant/>}></Route>
          <Route path='/buy' element={<Openaccount/>}></Route>
          <Route path='/credio' element={<Credio/>}></Route>
          <Route path='/contact' element={<Contactus/>}></Route>
          <Route path='/faqs' element={<Faq/>}></Route>
          <Route path='/policy' element={<Policy/>}></Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
