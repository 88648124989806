import './Main.css';
import Topbar from "../../Components/Topbar/Topbar";
import traced from '../../Assets/traced.png';
import image1 from "../../Assets/image1.png";
import image2 from "../../Assets/image2.png";
import image3 from "../../Assets/image3.png";
import image4 from "../../Assets/image4.png";
import image5 from "../../Assets/image5.png";
import image6 from "../../Assets/image6.png";
import credio from '../../Assets/credio.png';
import merchant from '../../Assets/merchant.png';
import reader from '../../Assets/reader.png'
import dot from '../../Assets/Dot.png';
import arrow from '../../Assets/arrow.png';
import credioimage from '../../Assets/credioimage.png'
import {MdArrowRightAlt} from 'react-icons/md'
import {TbArrowWaveRightUp} from 'react-icons/tb'
import { useEffect, useState } from 'react';
import Footer from '../../Components/Footer/Footer';
import { Link } from 'react-router-dom';
const images = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6
];
const Main = () => {
    //FOR IMAGE SLIDESHOW
    const [currentImage, setCurrentImage] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentImage((currentImage + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, [currentImage, images]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return ( 
        <div className="main">
            <Topbar/>
            <div className="main-body">
                <section className="main-header">
                    <div className="main-header-left">
                        <div className="header-text">
                            <p className="main-header-title"><span>Payment <img src={traced}></img></span> built for<br></br> Business Owners</p>
                            <p className="main-header-text">With 3-clicks receive payment for business transactions</p>
                        </div>
                        <div className="header-button">
                            <Link to="/credio">
                                <button className="header-button-credio"> Credio.</button>
                            </Link>
                            <Link to="/merchant">
                                <button className="header-button-merchant"> Credio Merchant <MdArrowRightAlt/></button>
                            </Link>
                        </div>
                    </div>
                    <div className="main-header-right">
                        <img src={images[currentImage]}></img>
                    </div>
                </section>
                <section className="main-content">
                    <div className="financial-solution">
                        <p className="financial-solution-header">Financial Solution For <span>Business Owners</span></p>
                        <div className="solutions">
                            <div className="solution-credio">
                                <div className="solution-image">
                                    <img  className='preview' src={credio}></img>
                                </div>
                                <div className="solution-body solution-body-2">
                                    <p className="solution-name">Credio</p>
                                    <p className="solution-about">A banking solution perfectly built for your business</p>
                                    <Link to='/credio'><p className="solution-link">GET STARTED <TbArrowWaveRightUp/></p></Link>
                                </div>
                            </div>
                            <div className="solution-credio">
                                <div className="solution-image">
                                    <img className='preview' src={merchant}></img>
                                    <img className='dot1' src={dot}></img>
                                </div>
                                <div className="solution-body">
                                    <p className="solution-name">Credio Merchant</p>
                                    <p className="solution-about">A secure payment solution for every business</p>
                                    <Link to='/merchant'><p className="solution-link link-merchant">GET STARTED <TbArrowWaveRightUp/></p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="your-business">
                        <div className="your-business-left">
                            <div className="business-left-content">
                                <p className="business-left-title">Your Business, Your Payment</p>
                                <p className="business-left-text">Accept payment easily, quickly and securely</p>
                            </div>
                            <div className="business-bar"></div>
                            <Link to="/merchant">
                                <button className='business-button'>Credio Merchant</button>
                            </Link>
                        </div>
                        <div className="your-business-right">
                            <img className='credioimage' src={reader}></img>
                        </div>
                    </div>
                    <div className="your-business your-business-2">
                        <div className="your-business-right credio-img">
                            <img className='credioimage credioimage-2' src={credioimage}></img>
                            <img className='dot2' src={dot}></img>
                        </div>
                        <div className="your-business-left your-business-left-2">
                            <div className="business-left-content business-left-content-2">
                                <p className="business-left-title">Your Business, Your Finance</p>
                                <p className="business-left-text">We will always help you in keep doing it better</p>
                            </div>
                            <div className="business-bar"></div>
                            <Link to="/credio">
                                <button className='business-button'>Credio</button>
                            </Link>
                        </div>
                    </div>
                </section>
                <section className="process">
                    <div className="process-header">
                        <div className="process-header-inner">
                            <p className="theprocess">The Process</p>
                            <div className="process-bar-1"></div>
                            <div className="process-bar-2"></div>
                            <div className="process-bar-3"></div>
                        </div>
                    </div>
                    <div className="credio-merchant-process">
                        <div className="credio-process">
                            <p className="credio-process-header">Credio</p>
                            <div className="credio-processes">
                                <div className="step">
                                    <img src={arrow}></img>
                                    <p className="steps">Step 1 :</p>
                                    <p className="step-process set-process-2">Enter your Business name and Phone number</p>
                                </div>
                                <div className="step">
                                    <p className="steps steps-2">Step 2 :</p>
                                    <p className="step-process">Enter OTP</p>
                                    <img className='rotate-arrow' src={arrow}></img>
                                </div>
                                <div className="step">
                                    <img src={arrow}></img>
                                    <p className="steps">Step 3 :</p>
                                    <p className="step-process">Set your Password</p>
                                </div>
                                <div className="step">
                                    <p className="steps steps-2">Step 4 :</p>
                                    <p className="step-process set-process-2">Your business account successfully opened</p>
                                </div>
                            </div>
                        </div>
                        <div className="merchant-process">
                            <p className="credio-process-header credio-process-header-2">Credio Merchant</p>
                            <div className="credio-processes merchant-processes">
                                <div className="step">
                                    <p className="steps steps-2">Step 1 :</p>
                                    <p className="step-process">Enter Amount</p>
                                    <img src={arrow} className='rotate-arrow' ></img>
                                </div>
                                <div className="step">
                                    <img src={arrow} ></img>
                                    <p className="steps">Step 2 :</p>
                                    <p className="step-process">Insert/tap the Credio reader</p>
                                </div>
                                <div className="step">
                                    <p className="steps steps-2">Step 3 :</p>
                                    <p className="step-process">Enter your PIN</p>
                                    <img src={arrow} className='rotate-arrow' ></img>
                                </div>
                                <div className="step">
                                    <p className="steps steps-2">Step 4 :</p>
                                    <p className="step-process">Payment received</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="join-credio">
                    <p className="join">Join Crediometer</p>
                    <p className="open-account"><span>"</span>Open an account just with<span>"</span><br></br>your Phone number and <br></br>Business name</p>
                    <Link to='/credio'><button className="get-started">Get Started</button></Link>
                </section>
                <Footer/>
            </div>
        </div>
    );
}
 
export default Main;