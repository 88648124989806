import './Credio.css'
import Topbar from "../../Components/Topbar/Topbar";
import credioapp from '../../Assets/credioapp.png'
import appstore from '../../Assets/appstore.png'
import playstore from '../../Assets/playstore.png'
import image9 from '../../Assets/image9.png'
import image10 from '../../Assets/image10.png'
import image11 from '../../Assets/image11.png'
import image12 from '../../Assets/image12.png'
import dot from '../../Assets/Dot.png'
import Footer from '../../Components/Footer/Footer';
import { useEffect } from 'react';
const Credio = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return ( 
        <div className="credio">
            <Topbar/>
            <div className="credio-body">
                <section className="merchant-header credio-header">
                    <p className="page-title">Credio</p>
                    <div className="merchant-header-inner">
                        <div className="merchant-header-left">
                            <h1 className="merchant-title credio-title">Banking built for <span>your business</span>.</h1>
                            <p className="credio-text">Banking built for business owners</p>
                            <div className="download credio-download">
                                <a href='https://play.google.com/store/apps/details?id=com.crediometer.credio' target="_blank"><img src={playstore}></img></a>
                                <a href='http://abbrefy.xyz/74b25ec' target="_blank"><img src={appstore}></img></a>
                            </div>
                        </div>
                        <div className="merchant-header-right credio-header-right">
                            <img src={credioapp}></img>
                        </div>
                    </div>
                </section>
                <section className="credio-content merchant-content">
                    <div className="what-reader">
                        <div className="what-reader-left what-credio-left">
                            <img src={image9}></img>
                            <div className="dot-6">
                                <img src={dot}></img>
                            </div>
                        </div>
                        <div className="what-reader-right">
                            <h2 className="what-reader-title">What is Credio</h2>
                            <p className="what-reader-text">With just the business name and phone number allow business owners of all shapes and sizes open a business account  for all their business transactions.</p>
                            <p className='what-reader-text what-credio-span'>Open an account with just 3 clicks at you comfort </p>
                        </div>
                    </div>
                </section>
                <div className="what-merchant what-credio why-credio">
                    <div className="what-merchant-left">
                        <h2 className="what-reader-title">Who needs Credio</h2>
                        <p className="what-reader-text">All business owners. The online sellers, the store owners, artisans, drivers etc. </p>
                        <p className="what-reader-text">Credio makes it easier to monitor business transactions to sell in person, online, over the phone and website or out in the field. It’s simple to use, and there’s no training required.</p>
                    </div>
                    <div className="what-merchant-right what-credio-right">
                        <img src={image10}></img>
                        <div className="dot-7">
                            <img src={dot}></img>
                        </div>
                    </div>
                </div>
                <section className="credio-content merchant-content">
                    <div className="what-reader">
                        <div className="what-reader-left what-credio-left">
                            <img src={image11}></img>
                            <div className="dot-8">
                                <img src={dot}></img>
                            </div>
                        </div>
                        <div className="what-reader-right">
                            <h2 className="what-reader-title">Make your customers back again and again.</h2>
                            <p className="what-reader-text">Grow customer loyalty with powerful customer management software, and understand your customers better with every card payment.</p>
                        </div>
                    </div>
                </section>
                <section className="download-credio">
                    <div className="download-credio-left">
                        <img src={image12}></img>
                    </div>
                    <div className="download-credio-right">
                        <p>Download Credio</p>
                        <div className="download credio-download">
                            <a href='https://play.google.com/store/apps/details?id=com.crediometer.credio' target="_blank"><img src={playstore}></img></a>
                            <a href='http://abbrefy.xyz/74b25ec' target="_blank"><img src={appstore}></img></a>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
}
 
export default Credio;