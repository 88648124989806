import { useEffect, useRef, useState} from 'react';
import logo from '../../Assets/logo.png';
import {FaChevronDown, FaBars, FaTimes} from 'react-icons/fa'

import './Topbar.css';
import { Link, useLocation } from 'react-router-dom';
const Topbar = () => {
    const location = useLocation();
    const [backgroundColor, setBackgroundColor] = useState('#fff');
    const [Color, setColor] = useState('#fff');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const navRef = useRef()
    const showNavbar = () =>{
        navRef.current.classList.toggle("responsive-nav")
    }
    const handleMenuToggle = () => {
      setIsMenuOpen(!isMenuOpen);
    };
    useEffect(() => {
        // change background color based on the current URL
        if (location.pathname === '/faqs' || location.pathname === '/policy') {
          setBackgroundColor('#B11226');
          setColor('#fff');
        
        } else {
          setBackgroundColor('#fff');
          setColor('#000')
        }

        function handleResize() {
            setIsSmallScreen(window.innerWidth < 900);
          }
      
          handleResize();
          window.addEventListener('resize', handleResize);
      
          return () => {
            window.removeEventListener('resize', handleResize);
          };
      }, [location.pathname]);
    return ( 
        <section className="topbar" style={{ backgroundColor, color: isSmallScreen ? 'black' : Color}}>
            <Link to="/">
                <div id="credio-logo">
                    <img src={logo}></img>
                </div>
            </Link>
            <div className="menu" ref={navRef}>
                <div className="menu-top">
                    <Link to="/">
                        <div id="credio-logo">
                            <img src={logo}></img>
                        </div>
                    </Link>
                    <button className='nav-btn nav-close' onClick={showNavbar} style={{color: Color}}>
                        <FaTimes/>
                    </button> 
                </div>
                <ul>
                    <li onClick={handleMenuToggle}>Product <span><FaChevronDown/></span></li>
                        {isMenuOpen && (
                            <div className='sub-menu'>
                                <Link to='/credio'>
                                    <section className="sub-menu-list">
                                            <div className="list-logo">
                                                <img src={logo}></img>
                                            </div>
                                        <div className="sub-menu-list-content">
                                            <p className="list-name" style={{ color: isSmallScreen ? 'black' : Color}}>Credio</p>
                                            <p className='list-text' >Solution for business</p>
                                        </div>
                                    </section>
                                </Link>
                                <Link to='/merchant'>
                                    <section className="sub-menu-list">
                                        <div className="list-logo">
                                            <img src={logo}></img>
                                        </div>
                                        <div className="sub-menu-list-content">
                                            <p className="list-name" style={{ color: isSmallScreen ? 'black' : Color}}>Merchant</p>
                                            <p className='list-text' >Solution for business</p>
                                        </div>
                                    </section>
                                </Link>
                            </div>
                        )}
                    <Link to="/policy"><li style={{ color: isSmallScreen ? 'black' : Color }}>Privacy</li></Link>
                    <Link to="/faqs"><li style={{ color:  isSmallScreen ? 'black' : Color}}>FAQS</li></Link>
                </ul>
                <div className="menu-button">
                    <Link to='/buy'>
                        <button className="buy-reader">
                            Buy Credio Reader
                        </button>
                    </Link>
                    <Link to='/contact'>
                        <button className="contact-us">
                            Contact Us
                        </button>     
                    </Link>
                </div>          
            </div>
            <button className='nav-btn' onClick={showNavbar} style={{color: Color}}>
                <FaBars/>
            </button>
        </section>
    );
}
 
export default Topbar;