export const crediocard = [
    {
        id: 1,
        question:"I’ve lost my  Credio Card. what should i do ?",
        answer:"Kindly deactivate the lost card from your Credio account. Then request from a new ATM card using your Credio profile."
    },
    {
        id: 2,
        question:"How do I activate my Credio Card card ?",
        answer:"You can activate your Credio Card on your Credio app"
    },
    {
        id: 3,
        question:"Can I withdraw cash from ATMs with  my credio card ?",
        answer:"Yes! Credio Card works on all ATMs and POS"
    },
    {
        id: 4,
        question:"What happens if i forget my password ",
        answer:"Kindly click on the Forgot Password option"
    },
    {
        id: 5,
        question:"How do I set my Credio Card PIN?",
        answer:"You can set it Via your Credio app"
    },
    {
        id: 6,
        question:"There’s a charge I don’t recognize on my card. What should I do?",
        answer:"Kindly reach out to support for explanation"
    }
]