import './Contactus.css'
import Topbar from "../../Components/Topbar/Topbar"
import image13 from '../../Assets/image13.png';
import dot from '../../Assets/Dot.png';
import Footer from '../../Components/Footer/Footer';
import {RiAttachment2} from 'react-icons/ri'
import { useEffect, useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';
import Modal from '../../Components/Modal/Modal';
import Contact from '../../Components/Modal/Contact';
import { Link } from 'react-router-dom';
const Contactus = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [subject, setSubject] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [file, setFile] = useState(null);
    const[image, setImage] = useState(null)
    const[filename, setFilename] = useState('')
    const [show, setshow] = useState(false);
    const [ispending, setIspending] = useState(false)
    const hideModalHandler = (e) => {
        e.preventDefault();
        setshow(false);
    }; 
    const handleSubmit = (e) => {
        e.preventDefault();
        const body ={email, name, subject, category, description}
        const contact = {body, file:filename}
        setIspending(true)

        fetch('https://credio-api.herokuapp.com/api/v1/contact-us', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(contact)
        }).then((res) => {
            setshow(true)
            setIspending(false)
            setName('')
            setEmail('')
            setDescription('')
            setCategory('')
            setSubject('')
            setFilename('')
        }) .catch(error => console.log(error));
    }
    const isDisabled = !(name && email && subject && category && description);
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return ( 
        <div className="contact">
            <Topbar/>
            <div className="contact-body">
                <section className="contact-header">
                    <h1 className="contact-header-title">Contact Us</h1>
                    <p className="contact-header-text">We’d like to help you with any<br></br> questions you may have. Get in touch!</p>
                </section>
                <section className="contact-content">
                    <div className="contact-content-left">
                        <img src={image13}></img>
                        <div className="dot-10">
                            <img src={dot}></img>
                        </div>
                    </div>
                    <div className="contact-content-right">
                        <form onSubmit={handleSubmit}>
                            <div className="inputfield">
                                <p>Email Address<span>*</span></p>
                                <input
                                    type="text"
                                    value={email} onChange={e => setEmail(e.target.value)} 
                                    required 
                                ></input>
                            </div>
                            <div className="inputfield">
                                <p>Name<span>*</span></p>
                                <input
                                    type="text"
                                    value={name} onChange={e => setName(e.target.value)}
                                    required 
                                ></input>
                            </div>
                            <div className="inputfield">
                                <p>Subject<span>*</span></p>
                                <input
                                    type="text"
                                    value={subject} onChange={e => setSubject(e.target.value)}
                                    required 
                                ></input>
                            </div>
                            <div className="inputfield">
                                <p>Category<span>*</span></p>
                                <select name="" id=""
                                    value={category} 
                                    onChange={e => setCategory(e.target.value)}
                                >
                                    <optgroup>
                                        <option value="general" selected>General</option>
                                        <option value="merchant">Merchant</option>
                                        <option value="credio card">Credio Card</option>
                                        <option value="credio reader">Credio Reader</option>
                                    </optgroup>
                                </select>
                            </div>
                            <div className="inputfield">
                                <p>Description<span>*</span></p>
                                <textarea
                                    type="text"
                                    value={description} onChange={e => setDescription(e.target.value)}
                                    required 
                                ></textarea>
                            </div>
                            <div className="inputfield">
                                <p>Attachment(optional)</p>
                                <div className="selectfile" onClick={()=>document.querySelector(".upload").click()}>
                                    <input type="file" className='upload' hidden
                                        onChange={({target: {files}})=>{
                                            files[0] && setFilename(files[0].name)
                                            if(files){
                                                setImage(URL.createObjectURL(files[0]))
                                            }
                                        }}
                                    ></input>
                                    <p><RiAttachment2/>Add a file</p>
                                </div>
                                {filename!=''?(
                                    <p className='select-filename'><span onClick={()=>{setFilename(""); setImage(null)}}><FaTimesCircle/> Remove File</span>{filename}</p>
                                    ):
                                    <div></div>
                                }
                            </div>
                            <p className="instructions">By clicking submit, I consent to crediometer storing my email for the purpose of sending me communication related to my request, also the phone number provided is going to get a flash message instantly authorize the transaction to proceed. View crediometer <Link to="/policy"><span>Privacy Policy</span>.</Link></p>
                            <div className="form-submit">
                                {!ispending && <button >Send Request</button>}
                                {ispending && <button disabled>Sending .....</button>}
                            </div>
                        </form>
                    </div>
                </section>
                {
                    show &&  <Contact hide={hideModalHandler}/>
                }
            </div>
            <Footer/>
        </div>
    );
}
 
export default Contactus;