import './Card.css';
import logo from '../../Assets/logo.png';
const Card = () => {
    return ( 
        <div className="card">
                <div className="card-top">
                    <div className="card-logo"><img src={logo}></img></div>
                    <div className="card-user">
                        <div className="card-gmail">Mosun78@gmail.com</div>
                        <p>Pay <span>NGN 15,000</span></p>
                    </div>
                </div>
                <div className="card-body">
                    <p className="card-title">Enter your card details to pay</p>
                    <form>
                        <div className="form-1">
                            <div className="inputfield-2">
                                <p>CARD NUMBER</p>
                                <input
                                type="text"
                                placeholder='0000 0000 0000 0000'
                                > 
                                </input>
                            </div>
                        </div>
                        <div className="form-2">
                            <div className="form-side">
                                <div className="inputfield-2">
                                    <p>CARD EXPIRY</p>
                                    <input
                                    type="text"
                                    placeholder='MM / YY'
                                    > 
                                    </input>
                                </div>
                            </div>
                            <div className="form-side">
                                <div className="inputfield-2">
                                    <p>CVV</p>
                                    <input
                                    type="text"
                                    placeholder='123'
                                    > 
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className="form-submit-2">
                            <button>Pay NGN 15,000</button>
                        </div>
                    </form>
                </div>
        </div>
    );
}
 
export default Card;