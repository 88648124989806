import './Faq.css';
import Topbar from "../../Components/Topbar/Topbar";
import { FaSearch } from 'react-icons/fa';
import { general } from './General';
import { crediocard } from './Crediocard';
import { credioreader } from './CredioReader';
import { useEffect, useState } from 'react';
import Accordion from './Accodion';
import Footer from '../../Components/Footer/Footer';
import ScrollTop from '../../Components/ScrollTop';
const Faq = () => {
    const [data, setData] = useState(general)
    const [credio, setCredio] = useState(crediocard)
    const [reader, setReader] = useState(credioreader)
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    // const search = (data) => {
    //     return data.filter((item) => item.first_name.toLowerCase().includes (se));
    // };
    return ( 
        <div className="faq">
            <Topbar/>
            <div className="faq-body">
                <section className="faq-header">
                    <p className="page-title page-title-faq">FAQs</p>
                    <div className="faq-search">
                        <div className="faq-search-inner">
                            <input
                            type="text"
                            placeholder="How can we help?"
                            // onChange={(e) => setSearchQuery(e.target.value)}
                            ></input>
                            <FaSearch/>
                        </div>
                    </div>
                </section>
                <section className="faq-content">
                    <div className="faq-content-inner">
                        <div className="faq-section">
                            <p className="faq-section-title">General</p>
                            <div className="faq-accordion">
                                {
                                    data.map((general)=>{
                                        const {id} = general
                                        return <Accordion key={id} {...general}/>
                                    })
                                }
                            </div>
                        </div>
                        <div className="faq-section">
                            <p className="faq-section-title">Credio Card</p>
                            <div className="faq-accordion">
                                {
                                    credio.map((general)=>{
                                        const {id} = general
                                        return <Accordion key={id} {...general}/>
                                    })
                                }
                            </div>
                        </div>
                        <div className="faq-section">
                            <p className="faq-section-title">Credio Reader </p>
                            <div className="faq-accordion">
                                {
                                    reader.map((general)=>{
                                        const {id} = general
                                        return <Accordion key={id} {...general}/>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer/>
        </div>
    );
}

export default Faq;
