import Topbar from "../../Components/Topbar/Topbar";
import cardreader from '../../Assets/cardreader.png';
import './Openaccount.css';
import Footer from "../../Components/Footer/Footer";
import Modal from "../../Components/Modal/Modal";
import { useState } from "react";
import Comingsoon from "../../Components/Modal/Comingsoon";
const Openaccount = () => {
    const [showModal, setShowModal] = useState(false);
    const showModalHandler = (e) => {
        e.preventDefault();
        setShowModal(true);
    }; 
    const hideModalHandler = (e) => {
        e.preventDefault();
        setShowModal(false);
      };   
    return ( 
        <div className="openaccount">
            <Topbar/>
            <div className="openaccount-inner">
                <div className="openaccount-left">
                    <img src={cardreader}></img>
                </div>
                <div className="openaccount-right">
                    <form>
                        <div className="inputfield">
                            <p>Phone Number<span>*</span></p>
                            <input
                                type="text"
                                placeholder="Enterphone number"
                            ></input>
                        </div>
                        <div className="inputfield">
                            <p>Address<span>*</span></p>
                            <input
                                type="text"
                                placeholder="Address should be detailed "
                            ></input>
                        </div>
                        <div className="inputfield">
                            <p>Quantity<span>*</span></p>
                            <input
                                type="text"
                                placeholder="Quantity(1-10)"
                            ></input>
                        </div>
                        <div className="inputfield">
                            <p>Additional text (optional)</p>
                            <textarea
                                type="text"
                            ></textarea>
                        </div>
                        <p className="instructions">By clicking submit, I consent to crediometer storing my email for the purpose of sending me communication related to my request, also the phone number provided is going to get a flash message instantly authorize the transaction to proceed. View crediometer <span>Privacy Policy</span>.</p>
                        <div className="form-submit">
                            <button onClick={showModalHandler}>Buy Now</button>
                        </div>
                    </form>
                </div>
                {
                    showModal &&  <Modal hidemodal={hideModalHandler}/>
                }
            </div>
            <Footer/>
        </div>
    );
}
 
export default Openaccount;