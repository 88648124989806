import './Modal.css';
import {BsBank2, BsCreditCard2Back} from 'react-icons/bs'
import { MdOutlineCreditCardOff } from 'react-icons/md';
import { TbCreditCardOff } from 'react-icons/tb';
import Card from './Card';
import Bank from './Bank';
import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
const Modal = ({hidemodal}) => {
    const [show, setShow] = useState(1);
    const style = {
        color: show === 1 ? "#058B42" : show === 2 ? "#058B42" : "black",
      };
    return ( 
        <div className="modal">
            <div className="modal-inner">
                <div className="modal-close" onClick={hidemodal}>
                    <FaTimes/>
                </div>
                <div className="modal-left">
                    <div className="modal-left-content">
                        <p className="modal-left-title">PAY WITH</p>
                    </div>
                    <div className="modal-left-content">
                        <p className="modal-left-text" onClick={()=>{setShow(1)}}><BsCreditCard2Back/>Card</p>
                    </div>
                    <div className="modal-left-content">
                        <p className="modal-left-text"><TbCreditCardOff/>Cardless Transaction </p>
                    </div>
                    <div className="modal-left-content">
                        <p className="modal-left-text" onClick={()=>{setShow(2)}}><BsBank2/>Bank Transfer</p>
                    </div>
                    <div className="modal-left-content">
                        <div className="modal-content-inner">
                            <p className="modal-left-text"><span>*#</span>USSD</p>
                            <div className="comming-soon">
                                <p>Coming soon</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-right">
                    {
                        show === 1 ? <Card/> : <Bank/>
                    }
                </div>
            </div>
        </div>
    );
}
 
export default Modal;