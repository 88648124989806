import { Link } from 'react-router-dom';
import logo from '../../Assets/logo.png';
import './Footer.css';
const Footer = () => {
    return ( 
        <section className="footer">
            <div className="footer-body">
                <div className="footer-body-left">
                    <img src={logo}></img>
                    <p className="what-we-do">Payment built for business owners</p>
                    <p className="location">220 Oyemekun road, beside Michelin Tyres Services, Airways Akure.</p>
                    <p className="lettalk">Let’s Talk:  0805 588 7077</p>
                </div>
                <div className="footer-body-right">
                    <div className="company">
                        <h4 className="footer-head">Company</h4>
                        <p className="footer-body-2">About Us</p>
                        <Link to='/contact'><p className="footer-body-2">Contact Us</p></Link>
                        <Link to="/policy"><p className="footer-body-2">Privacy Policy</p></Link>
                        <p className="footer-body-2">Terms of Service</p>
                        <Link to="/faqs"><p className="footer-body-2">FAQS</p></Link>
                    </div>
                    <div className="company">
                        <h4 className="footer-head">Socials</h4>
                        <a href='https://www.facebook.com/thegeniuscentre?mibextid=ZbWKwL' target="_blank"><p className="footer-body-2">Facebook</p></a>
                        <a href='https://www.linkedin.com/company/crediometer/' target="_blank"><p className="footer-body-2">LinkedIn</p></a>
                        <a href='https://instagram.com/crediometer?igshid=YmMyMTA2M2Y=' target="_blank"><p className="footer-body-2">Instagram </p></a>
                    </div>
                    <div className="company">
                        <h4 className="footer-head">Products</h4>
                        <Link to='/credio'><p className="footer-body-2">Credio</p></Link>
                        <Link to='/merchant'><p className="footer-body-2">Credio merchant</p></Link>
                        <Link to='/buy'><p className="footer-body-2">Credio reader</p></Link>
                        <p className="footer-body-2">Business tool</p>
                    </div>
                </div>
            </div>
            <p className="credio-footer">Crediometer is a fintech, not a bank. Deposit account services provided by SafeHaven MicroFinance Bank.</p>
        </section>
    );
}
 
export default Footer;