import './Merchant.css'
import Topbar from "../../Components/Topbar/Topbar";
import Footer from '../../Components/Footer/Footer'
import reader from '../../Assets/reader.png';
import cardreader from '../../Assets/cardreader.png'
import merchant from '../../Assets/merchantpreview.png'
import appstore from '../../Assets/appstore.png'
import playstore from '../../Assets/playstore.png'
import image7 from '../../Assets/image7.png'
import image8 from '../../Assets/image8.png'
import buyreader from '../../Assets/buyreader.png';
import steps from '../../Assets/steps.png'
import chart from '../../Assets/chart.png'
import filter from '../../Assets/filter.png'
import network from '../../Assets/network.png';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
const Merchant = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return ( 
        <div className="merchant">
            <Topbar/>
            <div className="merchant-body">
                <section className="merchant-header">
                    <p className="page-title">Credio Merchant & Reader</p>
                    <div className="merchant-header-inner">
                        <div className="merchant-header-left">
                            <h1 className="merchant-title">A 3-click Secure and Easy-to-use <span>Payment Solution</span> for your Business</h1>
                            <Link to='/buy'><button className="merchant-header-button">Open Account</button></Link>
                        </div>
                        <div className="merchant-header-right">
                            <img src={reader}></img>
                        </div>
                    </div>
                </section>
                <section className="merchant-content">
                    <div className="what-reader">
                        <div className="what-reader-left">
                            <img src={cardreader}></img>
                        </div>
                        <div className="what-reader-right">
                            <h2 className="what-reader-title">What is a Credio Reader?</h2>
                            <p className="what-reader-text">Our electronic point of sale (Credio Reader) helps you run your business by managing your sales, payments, records, inventory and more. This handy device works along side your Credio merchant app installed on your phone or access it via web.</p>
                        </div>
                    </div>
                    <div className="what-merchant">
                        <div className="what-merchant-left">
                            <h2 className="what-reader-title">Who is a Credio Merchant and who needs it ?</h2>
                            <p className="what-reader-text">This is an easy-to-use app that allows you to receive digital payment both online (using the Credio reader) and offline by asking for the payer’s phone number. Sound interesting? Yes, it is.</p>
                            <div className="download-merchant">
                                <p className="download-merchant-text">Download Credio Merchant</p>
                                <div className="download">
                                    <img src={playstore}></img>
                                    <img src={appstore}></img>
                                </div>
                            </div>
                        </div>
                        <div className="what-merchant-right">
                            <img src={merchant}></img>
                        </div>
                    </div>
                </section>
                <section className="why-credio">
                    <div className="why-credio-left">
                        <img src={image7}></img>
                        <h2 className="why-credio-title">Start selling wherever your <span>customers</span> are.</h2>
                        <p className='why-credio-text'>Credio Merchant makes it easy to sell in person, online, over the phone and website or out in the field. It’s simple to use, and there’s no training required.</p>
                    </div>
                    <div className="why-credio-right">
                        <img src={image8}></img>
                        <h2 className="why-credio-title">Make your customers come back <span>again and again</span>.</h2>
                        <p className='why-credio-text'>Grow customer loyalty with powerful customer management software, and understand your customers better with every card payment.</p>
                    </div>
                </section>
                <section className="buy-readers">
                    <h2 className="buy-reader-title">Buy Credio Reader</h2>
                    <div className="buy-reader-inner">
                        <div className="buy-reader-left">
                            <div className="buy-reader-left-inner">
                                <img src={steps}></img>
                                <div className="buy-reader-steps">
                                    <div className="reader-step">
                                        <h2 className="reader-step-title">Create a  merchant account</h2>
                                        <p className="reader-step-text">Create account in few minutes by providing necessary information.</p>
                                    </div>
                                    <div className="reader-step">
                                        <h2 className="reader-step-title">Input details of delivery </h2>
                                        <p className="reader-step-text">Choose preferred location in which the reader can be delivered</p>
                                    </div>
                                    <div className="reader-step">
                                        <h2 className="reader-step-title">Make payment for the reader</h2>
                                        <p className="reader-step-text">Select preferred payment option </p>
                                    </div>
                                    <div className="reader-step">
                                        <h2 className="reader-step-title">You have your reader ready for business</h2>
                                        <p className="reader-step-text">Make money and refer a friend </p>
                                    </div>
                                </div>
                            </div>
                            <Link to='/buy'>
                                <button className="buy-now">Buy now</button>
                            </Link>
                        </div>
                        <div className="buy-reader-right">
                            <img src={buyreader}></img>
                        </div>
                    </div>
                </section>
                <section className="benefit">
                    <div className="benefits">
                        <img src={chart}></img>
                        <p>Powerful, free analytics</p>
                    </div>
                    <div className="benefits">
                        <img src={filter}></img>
                        <p>Flexibility meets scalability</p>
                    </div>
                    <div className="benefits">
                        <img src={network}></img>
                        <p>Integrate with existing software</p>
                    </div>
                </section>
                <Footer/>
            </div>
        </div>
    );
}
 
export default Merchant;