import './Bank.css';
import logo from '../../Assets/logo.png';
import {FiCopy} from 'react-icons/fi'
const Bank = () => {
    return ( 
        <div className="bank">
            <div className="card-top">
                <div className="card-logo"><img src={logo}></img></div>
                <div className="card-user">
                    <div className="card-gmail">Mosun78@gmail.com</div>
                    <p>Pay <span>NGN 15,000</span></p>
                </div>
            </div>
            <div className="bank-body">
                <p className="bank-title">Open your bank app or internet banking portal and make a transfer into the following account.</p>
                <div className="bank-details">
                    <div className="bank-subdetail">
                        <p className="bank-detail-title">Account Number</p>
                        <p className="bank-detail-text">8066429634 <span><FiCopy/></span></p>
                    </div>
                    <div className="bank-subdetail">
                        <p className="bank-detail-title">Account Number</p>
                        <p className="bank-detail-text">8066429634</p>
                    </div>
                    <div className="bank-subdetail">
                        <p className="bank-detail-title">Account Name</p>
                        <p className="bank-detail-text">CREDIO CHECHOUT</p>
                    </div>
                    <div className="bank-subdetail">
                        <p className="bank-detail-title">Bank Name</p>
                        <p className="bank-detail-text">SafeHeaven MFB</p>
                    </div>
                    <div className="bank-subdetail">
                        <p className="bank-detail-title">Amount</p>
                        <p className="bank-detail-text">NGN 15,000</p>
                    </div>
                </div>
                <div className="form-submit-2">
                    <button>Pay NGN 15,000</button>
                </div>
            </div>
        </div>
    );
}
 
export default Bank;